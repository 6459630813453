import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useLanguage } from "../contexts/LanguageContext"

const AssetsList = ({ assets = [] }) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook

  const noAssetsMessage =
    selectedLanguage === "EN"
      ? "*Currently, there are no assets for sale*"
      : "*Trenutno nema imovine za prodaju*"

  return (
    <div className="jobs-list">
      {assets.map(asset => {
        const { id, naslov, slika, vrijediDo } = asset
        return (
          <div key={id} className="job-little-wrapper">
            {" "}
            {/* Moved the key prop here */}
            {naslov === "Prazna stranica" ? (
              <h3 className="about-paragraph estate">{noAssetsMessage}</h3>
            ) : (
              <Link to={`/${id}`} className="job">
                <GatsbyImage image={slika.gatsbyImageData} alt={naslov} />
                <h5>{naslov}</h5>
                {vrijediDo && <p>Oglas je otvoren do {vrijediDo}.</p>}
              </Link>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AssetsList
